<template>
  <div class="doctor_container">
    <!-- 头部 -->
    <div class="header">
      <div class="day">
        {{ jsonDay[dateIndex].year }}年{{ jsonDay[dateIndex].month }}月
      </div>
      <div class="date" v-show="currentIndex == 0">
        <div class="item" v-for="(item, index) in jsonDay" :key="index">
          <div>{{ item.week }}</div>
        </div>
      </div>
      <div class="date" v-show="currentIndex == 0">
        <div
          class="item"
          :class="tabIndex == index ? 'checked' : ''"
          :style="item.status === '1' ? '' : 'color: #666;'"
          v-for="(item, index) in doctorDate"
          :key="index"
          @click="choose($event, index)"
          :id="index"
        >
          <div>{{ item.day }}</div>
          <div style="font-size: 15px;">
            {{ item.status === "1" ? "有号" : "无号" }}
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="info">
      <van-image
        width="80"
        height="100"
        :src="pictureUrl"
        fit="contain"
        v-if="pictureUrl"
      />
      <van-image
        width="80"
        height="100"
        src="http://www.fkxrmyy.com/wxxcx/wx/image/doc.png"
        fit="contain"
        v-if="!pictureUrl"
      />
      <div class="content">
        <div>科室：{{ deptName }}</div>
        <div>姓名：{{ doctorName }}</div>
        <div>职称：{{ regType }}</div>
      </div>
      <!-- <van-button type="primary"
                  @click="collect"
                  class="collect"
                  v-if="!isCollect">收藏</van-button> -->
      <van-button
        type="info"
        @click="unCollect"
        class="collect"
        v-if="isCollect"
        >已收藏</van-button
      >
    </div>
    <div class="intro">
      <div class="title">个人简介</div>
      <div :class="more ? 'content' : 'more'" @click="showMore">
        {{ doctorIntrodution === "" ? "暂无信息" : doctorIntrodution }}
      </div>
    </div>
    <div class="timeSlot">
      已选：
      <span class="time">{{ searchDate }}</span
      >&nbsp;&nbsp;
      <span class="date">请选择就诊时段</span>
    </div>
    <div class="list">
      <!-- 当天 -->
      <div v-for="(item, index) in orderList" :key="index">
        <div class="tip" v-if="showTip">
          温馨提示:口腔科当天暂不支持线上挂号，可到医院线下挂号
        </div>
        <van-cell
          v-if="timeSlot == item.scheduleDate && deptCode != '0109010101'"
          :title="item.beginTime + '-' + item.endTime"
          is-link
          icon="underway"
          :border="false"
          class="item"
          @click="
            select(
              item.beginTime,
              item.endTime,
              item.regFee,
              item.timeFlag,
              item.deptCode,
              item.deptName,
              item.doctorCode,
              item.doctorName,
              item.position,
              item.workStatus,
              item.residueNum
            )
          "
        >
          <van-tag
            type="primary"
            size="large"
            plain
            v-if="item.workStatus === '1' && item.residueNum !== '0'"
            >余号{{ item.residueNum }}个</van-tag
          >
          <van-tag
            type="danger"
            size="medium"
            plain
            v-if="item.workStatus === '1' && item.residueNum === '0'"
            >余号{{ item.residueNum }}个</van-tag
          >
          <van-tag
            type="danger"
            size="large"
            plain
            v-if="item.workStatus === '2'"
            >已停诊</van-tag
          >
        </van-cell>
      </div>
      <!-- 非当天 -->
      <div v-for="(item, index) in orderList" :key="index">
        <van-cell
          v-if="timeSlot != item.scheduleDate"
          :title="item.beginTime + '-' + item.endTime"
          is-link
          icon="underway"
          :border="false"
          class="item"
          @click="
            select(
              item.beginTime,
              item.endTime,
              item.regFee,
              item.timeFlag,
              item.deptCode,
              item.deptName,
              item.doctorCode,
              item.doctorName,
              item.position,
              item.workStatus,
              item.residueNum
            )
          "
        >
          <van-tag
            type="primary"
            size="large"
            plain
            v-if="item.workStatus === '1' && item.residueNum !== '0'"
            >余号{{ item.residueNum }}个</van-tag
          >
          <van-tag
            type="danger"
            size="medium"
            plain
            v-if="item.workStatus === '1' && item.residueNum === '0'"
            >余号{{ item.residueNum }}个</van-tag
          >
          <van-tag
            type="danger"
            size="large"
            plain
            v-if="item.workStatus === '2'"
            >已停诊</van-tag
          >
        </van-cell>
      </div>
    </div>
    <van-empty description="暂无内容" v-if="orderList === ''" />
    <van-overlay lock-scroll="false" :show="show">
      <div class="doctor_wrapper">
        <div class="doctor_block">
          <div class="title">预&nbsp;&nbsp;约&nbsp;&nbsp;信&nbsp;&nbsp;息</div>
          <div class="top">
            <van-field
              readonly
              clickable
              label="就诊人"
              :value="patName"
              @click="patNamePickerShow = true"
              right-icon="arrow-down"
              class="picker"
            />
            <van-popup v-model="patNamePickerShow" round position="bottom">
              <van-picker
                show-toolbar
                :columns="patNameList"
                @cancel="patNamePickerShow = false"
                @confirm="patNameConfirm"
              />
              <van-button type="info" block class="add" @click="addPatience"
                >添&nbsp;加&nbsp;就&nbsp;诊&nbsp;人</van-button
              >
            </van-popup>
            <van-field
              label="就诊卡号"
              :value="patCardNo"
              readonly
              class="input"
            />
          </div>
          <div class="bottom">
            <div>科室：{{ deptName }}</div>
            <div>医生：{{ doctorName }}</div>
            <div>就诊日期：{{ searchDate }}</div>
            <div>就诊时间：{{ beginTime }}-{{ endTime }}</div>
            <div v-if="regFee != '0'">诊查费：{{ regFee }}元</div>
          </div>
          <div class="btn">
            <van-button type="info" @click="cancel"
              >取&nbsp;&nbsp;消</van-button
            >
            <van-button type="primary" @click="confirm"
              >确&nbsp;&nbsp;定</van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
import { setTimeout } from "timers";
export default {
  name: "Doctor",
  data() {
    return {
      doctorCode: "",
      timeSlot: "",
      jsonDay: [],
      dateIndex: 0,
      more: false,
      show: false,
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      patCardNo: "",
      doctorInfo: [],
      orderList: [],
      doctorName: "",
      deptName: "",
      regType: "",
      doctorIntrodution: "",
      pictureUrl: "",
      isCollect: false,
      removeId: "",
      beginTime: "",
      endTime: "",
      regFee: "",
      timeFlag: "",
      deptCode: "",
      position: "",
      end: "",
      doctorDate: [],
      currentIndex: 0,
      tabIndex: 0,
      searchDate: 0,
      showTip: false
    };
  },
  created() {
    this.doctorCode = this.$route.query.doctorCode;
    this.deptCode = this.$route.query.deptCode;
    this.levelCode = this.$route.query.levelCode;
    this.searchDate = this.$route.query.timeSlot;
    this.tabIndex = this.$route.query.tabIndex;
    this.dateIndex =
      this.$route.query.dateIndex === undefined
        ? 0
        : this.$route.query.dateIndex;

    this.end = this.formatTime(+6);
    this.getToday();
    this.getDay();
    this.getCollectionRecord();
    this.getDoctorInfo();
    this.getOrderList();
    this.getDoctorDay();
  },
  methods: {
    changeTab(index) {
      this.currentIndex = index;
    },
    // 获取日期数据
    async getDoctorDay() {
      this.allDoctor = "";
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        deptCode: this.deptCode,
        doctorCode: this.doctorCode,
        beginDate: this.timeSlot,
        endDate: this.end,
        levelCode: this.levelCode
      });
      const { data: res } = await jsonPost("/wxapp/his/dateschedulelist", data);
      if (res.code == 0) {
        this.doctorDate = res.data.item;
        this.dateIndex = this.$route.query.dateIndex = this.$route.query.dateIndex;
        for (let i = 0; i <= 6; i++) {
          this.doctorDate[i].day = this.doctorDate[i].scheduleDate.split(
            "-"
          )[2];
        }
      }
    },
    //获取医生详情
    async getDoctorInfo() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        doctorCode: this.doctorCode
      });
      const { data: res } = await formPost("/wxapp/his/getStaff", data);
      this.$toast.clear();
      if (res.code === 0) {
        this.doctorInfo = res.data.item;
        // this.doctorName = this.doctorInfo[0].doctorName;
        // this.deptName = this.doctorInfo[0].deptName;
        // this.regType = this.doctorInfo[0].doctorTitle;
        // this.doctorIntrodution = this.doctorInfo[0].doctorIntrodution;
      } else {
        // this.$toast.fail(res.msg);
      }
    },
    //获取号源
    async getOrderList() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        deptCode: this.deptCode,
        doctorCode: this.doctorCode,
        date: this.searchDate || this.timeSlot
      });
      const { data: res } = await formPost("/wxapp/his/getRegList", data);
      this.$toast.clear();
      if (res.code === 0) {
        this.orderList = res.data.item;
        this.pictureUrl = this.orderList[0].pictureUrl;
        this.doctorName = this.orderList[0].doctorName;
        this.deptName = this.orderList[0].deptName;
        this.regType = this.orderList[0].doctorTitle;
        this.doctorIntrodution = this.orderList[0].doctorIntrodution;
        this.orderList.map((item, index) => {
          if (
            this.timeSlot == item.scheduleDate &&
            this.deptCode == "0109010101"
          ) {
            if (!this.showTip) {
              // 如果变量为false，则显示温馨提示
              this.showTip = true; // 将变量设为true
              return (
                <div class="tip">
                  温馨提示:口腔科当天暂不支持线上挂号，可到医院线下挂号
                </div>
              );
            } else {
              return null; // 如果变量为true，则不显示温馨提示
            }
          } else if (
            this.timeSlot == item.scheduleDate &&
            this.deptCode != "0109010101"
          ) {
            // 渲染van-cell元素
          } else {
            return null; // 其他情况不渲染
          }
        });
      } else {
        this.orderList = "";
        this.$toast.fail(res.msg);
      }
    },
    //查询收藏状态
    async getCollectionRecord() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        doctorCode: this.doctorCode,
        openId: window.localStorage.getItem("openId")
      });
      const { data: res } = await formPost(
        "/api/wxapp/record/getCollectionRecord",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.isCollect = true;
        this.removeId = res.data;
      } else {
        this.isCollect = false;
      }
    },
    //收藏
    async add() {
      this.$toast.loading({
        duration: 0,
        message: "收藏中...",
        forbidClick: true
      });
      let data = qs.stringify({
        collectionMark: "0",
        doctorNum: this.doctorCode,
        openId: window.localStorage.getItem("openId")
      });
      const { data: res } = await formPost("/api/wxapp/record/add", data);
      this.$toast.clear();
      if (res.code === 0) {
        this.getCollectionRecord();
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //取消收藏
    async remove() {
      this.$toast.loading({
        duration: 0,
        message: "取消中...",
        forbidClick: true
      });
      let data = qs.stringify({
        id: this.removeId
      });
      const { data: res } = await formPost("/api/wxapp/record/remove", data);
      this.$toast.clear();
      if (res.code === 0) {
        this.getCollectionRecord();
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取建档人列表
    async getMembers() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId")
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name);
        }
        this.patName = this.patNameList[this.patNameIndex];
        this.patCardNo = this.patNameArray[this.patNameIndex].pat_card_no;
      } else {
        this.$toast.fail(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/file"
          });
        }, 2000);
      }
    },

    //锁定号源
    async lockReg() {
      this.$toast.loading({
        duration: 0,
        message: "预约中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/appointmentRecord/record/lockReg",
        {
          beginTime: this.beginTime,
          deptCode: this.deptCode,
          deptName: this.deptName,
          doctorCode: this.doctorCode,
          endTime: this.endTime,
          hospitalId: 100,
          medicalCardNo: this.patCardNo,
          openId: window.localStorage.getItem("openId"),
          regFee: this.regFee,
          scheduleDate: this.searchDate,
          timeFlag: this.timeFlag,
          doctorName: this.doctorName,
          competent: this.regType,
          position: this.position
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        console.log(res);
        if (res.data.isPay == 1) {
          this.$toast.success(res.msg);
          window.localStorage.setItem("isPay", res.data.isPay);
          setTimeout(() => {
            this.$router.push({
              path: "/register/status",
              query: {
                name: this.patName,
                deptName: this.deptName,
                doctorName: this.doctorName,
                timeSlot: this.timeSlot,
                beginTime: this.beginTime,
                endTime: this.endTime,
                regFee: res.data.regFee,
                medicalCardNo: this.patCardNo,
                isPay: res.data.isPay,
                position: this.position
              }
            });
          }, 2000);
        } else if (res.data.isPay == 0) {
          this.$router.push({
            path: "/register/detail",
            query: {
              appointmentId: res.data.appointmentId,
              orderNum: res.data.orderNum,
              name: this.patName,
              deptName: this.deptName,
              doctorName: this.doctorName,
              timeSlot: this.timeSlot,
              beginTime: this.beginTime,
              endTime: this.endTime,
              regFee: res.data.regFee,
              medicalCardNo: this.patCardNo,
              orderRecordId: res.data.orderRecordId,
              position: this.position,
              searchDate: this.searchDate
            }
          });
        } else if (res.data.isPay == 6) {
          this.$toast.success(res.msg);
          setTimeout(() => {
            this.$router.push({
              path: "/me/register"
            });
          }, 2000);
        }
      } else if (res.msg == "挂号失败，存在未付款的订单记录") {
        this.$toast.fail(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/order/register",
            query: {
              beginTime: this.beginTime,
              endTime: this.endTime,
              patCardNo: this.patCardNo
            }
          });
        }, 2000);
      } else {
        this.patName = "";
        this.patNameList = [];
        this.patNameArray = [];
        this.patNameIndex = 0;
        this.patCardNo = "";
        this.$toast.fail(res.msg);
      }
      this.show = false;
    },
    getToday() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let currentdate = year + "-" + month + "-" + strDate;
      this.timeSlot = currentdate;
    },
    getDay() {
      let arr = [];
      let day = [];
      let week = [];
      for (let i = 0; i <= 6; i++) {
        day.push(this.formatTime(i));
        week.push(this.formatWeek(this.formatTime(i)));
      }
      for (let i = 0; i < day.length; i++) {
        let str = day[i];
        let s = str.split("-");
        let json = {};
        for (let j = 0; j < s.length; j++) {
          json.year = s[0];
          json.month = s[1];
          json.day = s[2];
        }
        arr.push(json);
      }
      for (let i = 0; i < week.length; i++) {
        arr[i].week = week[i];
      }
      for (let i = 0; i < arr.length; i++) {
        arr[i].checked = false;
      }
      arr[0].checked = true;
      this.jsonDay = arr;
    },
    formatTime(num) {
      let now = new Date();
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let ShowTime = nowTime + num * oneDayTime;
      let myDate = new Date(ShowTime);
      let y = myDate.getFullYear(); //年
      let m =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let d = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate(); //日
      return y + "-" + m + "-" + d;
    },
    formatWeek(date) {
      let weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
      let week = weekArray[new Date(date).getDay()];
      return week;
    },
    collect() {
      this.add();
    },
    unCollect() {
      this.remove();
    },
    choose(e, indexs) {
      this.tabIndex = indexs;
      let list = this.jsonDay;
      for (let i = 0; i < list.length; i++) {
        list[i].checked = false;
      }
      let index = parseInt(e.currentTarget.id);
      let item = this.jsonDay[index];
      this.dateIndex = index;
      item.checked = !item.checked;
      this.searchDate =
        this.jsonDay[index].year +
        "-" +
        this.jsonDay[index].month +
        "-" +
        this.jsonDay[index].day;
      this.getOrderList();
    },
    showMore() {
      this.more = !this.more;
    },
    patNameConfirm(value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patCardNo = this.patNameArray[this.patNameIndex].pat_card_no;
      this.patNamePickerShow = false;
    },
    select(
      beginTime,
      endTime,
      regFee,
      timeFlag,
      deptCode,
      deptName,
      doctorCode,
      doctorName,
      position,
      workStatus,
      residueNum
    ) {
      if (workStatus == 2) {
        this.$toast.fail({
          message: "该时间段已停诊，请重新选择"
        });
      } else if (residueNum === "0" && workStatus === "1") {
        this.$toast.fail({
          message: "号源已挂满"
        });
      } else {
        this.beginTime = beginTime;
        this.endTime = endTime;
        this.regFee = regFee;
        this.timeFlag = timeFlag;
        this.deptCode = deptCode;
        this.deptName = deptName;
        this.doctorCode = doctorCode;
        this.doctorName = doctorName;
        this.position = position;
        this.getMembers();
        this.show = true;
      }
    },
    cancel() {
      this.show = false;
      this.patName = "";
      this.patNameList = [];
      this.patNameArray = [];
      this.patNameIndex = 0;
      this.patCardNo = "";
    },
    confirm() {
      this.show = false;
      this.lockReg();
    },
    addPatience() {
      this.$router.push({
        path: "/file"
      });
    }
  }
};
</script>

<style lang="less" scope>
.doctor_container {
  .header {
    position: sticky;
    top: 0;
    left: 0;
    overflow: hidden;
    border-bottom: 1px solid #f5f5f5;
    background-color: white;
    z-index: 1;
  }
}

.doctor_container .line {
  height: 10px;
  background-color: #f4f4f4;
}

.doctor_container .header .day {
  text-align: center;
  // display: flex;
  // justify-content: space-between;
  margin-top: 20px;
  color: #666;
  font-size: 46px;
  padding: 0 20px;

  .tab {
    margin-top: 5px;
    font-size: 32px;

    .active {
      background-color: #0252d9;
      color: #f4f4f4;
    }

    span {
      border: 1px solid #0252d9;
      padding: 15px 15px;
    }
  }
}

.doctor_container .header .date {
  display: flex;
  flex: 1;
}

.doctor_container .header .date .item {
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  font-size: 40px;
  line-height: 60px;

  .gray {
    color: #666;
  }
}

.tip {
  padding: 20px;
}

.doctor_container .header .date .checked {
  background-color: #0252d9;
  color: white !important;
  border-radius: 20px;
}

.doctor_container .info {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 20px 30px;
}

.doctor_container .info .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 40px;
  margin-left: 30px;
}

.doctor_container .info .content div {
  margin-top: 16px;
}

.doctor_container .info .collect {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-40%);
  font-size: 36px;
  padding: 20px;
}

.doctor_container .intro .title {
  font-size: 40px;
  padding: 20px 30px;
  border-bottom: 1px solid #f5f5f5;
}

.doctor_container .intro .content {
  font-size: 36px;
  color: rgba(87, 105, 123, 1);
  text-indent: 1em;
  padding: 10px 30px;
  line-height: 60px;
  border-bottom: 1px solid #f5f5f5;
}

.doctor_container .intro .more {
  font-size: 36px;
  color: rgba(87, 105, 123, 1);
  text-indent: 1em;
  padding: 10px 30px;
  line-height: 60px;
  border-bottom: 1px solid #f5f5f5;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.doctor_container .timeSlot {
  font-size: 40px;
  padding: 20px 30px;
  border-bottom: 1px solid #f5f5f5;
}

.doctor_container .timeSlot .time {
  color: orange;
}

.doctor_container .timeSlot .date {
  color: rgb(196, 83, 83);
}

.doctor_container .list {
  padding-bottom: 60px;
}

.doctor_container .list .item {
  padding: 30px;
  font-size: 40px;
  border-bottom: 1px solid #f5f5f5;
}

.doctor_container .list .item .van-tag {
  font-size: 40px;
  padding: 10px;
}

.doctor_container .list .item .van-icon {
  font-size: 40px;
  color: rgba(32, 112, 204, 0.753);
  line-height: 48px;
}

.doctor_container .doctor_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
}

.doctor_container .doctor_block {
  overflow: hidden;
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  font-size: 40px;
}

.doctor_container .doctor_block .title {
  background-color: #f5f5f5;
  font-size: 48px;
  text-align: center;
  padding: 20px 0;
}

.doctor_container .picker {
  font-size: 40px;
}

.doctor_container .add {
  margin-bottom: 60px;
}

.doctor_container .doctor_block .top,
.doctor_container .doctor_block .bottom {
  border: 2px solid rgb(194, 190, 190);
  margin: 20px 30px;
  border-radius: 10px;
  font-size: 40px;
  overflow: hidden;
}

.doctor_container .picker .van-field__label,
.doctor_container .input .van-field__label {
  text-align: left;
  font-size: 40px;
}

.doctor_container .picker .van-field__control {
  text-align: right;
  padding-right: 20px;
  font-size: 40px;
}

.doctor_container .input .van-field__control {
  text-align: right;
  font-size: 40px;
}

.doctor_container .van-picker__cancel,
.doctor_container .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.doctor_container .doctor_block .bottom div {
  padding: 20px 30px;
}

.doctor_container .doctor_block .btn {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
  padding: 0 40px;
}

.doctor_container .doctor_block .btn .van-button {
  font-size: 36px;
}

.doctor_container .doctor_block .van-cell::after {
  border: 0;
}

.doctor_container .doctor_block .van-icon {
  position: absolute;
  top: 0;
  right: -10px;
  font-size: 40px !important;
}

.doctor_container .doctor_block .van-picker-column__item {
  font-size: 40px;
}
</style>
